<template>
  <div class="no-access-page">
    <div class="no-access-page__title">
      {{ header }}
    </div>
    <div class="no-access-page__image-wrapper" :class="classes">
      <div class="image">
        <img
          v-if="classes === 'no-programs'"
          src="@/assets/images/svg/bookshelf.svg"
          alt="Компьютер"
        />
        <img v-else src="@/assets/images/svg/access-denied.svg" alt="Компьютер" />
      </div>
      <div class="image-title">
        {{ computedTitle }}
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'NoAvailableContent',
  props: {
    header: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedTitle() {
      if (!this.title) {
        return this.$t('errorMessages.toGetAccessContactTheAdministrator');
      }
      return this.title;
    },
  },
};
</script>
