<template>
  <div class="programs">
    <transition name="component-fade" mode="out-in">
      <div key="loading" v-if="loading" class="programs__loader">
        <div class="container f-center">
          <Loader />
        </div>
      </div>
      <div key="error" class="container mt-5" v-else-if="loadingError">
        <span @click="$router.go()" class="text-underline cursor-pointer">{{
          $t('errorMessages.somethingWentWrong')
        }}</span>
      </div>
      <div key="content" v-else-if="programs.length" class="programs__wrapper">
        <div class="programs__info-box info-box container">
          <div class="info-box__title">{{ $t('links.studyRoom') }}</div>
          <div class="info-box__description">
            {{ $t('supportText.selectAvailableProgram') }}
          </div>
        </div>
        <div class="container">
          <div class="programs__content">
            <b-row cols="1" cols-sm="2" cols-lg="3">
              <b-col v-for="program in programs" :key="program.id" class="program__container">
                <ProgramCardStudent :program="program" />
              </b-col>
            </b-row>
          </div>
          <Pagination
            v-if="numberOfPages > 1"
            :limit="paginationLimitButtons"
            :numberOfPages="numberOfPages"
          />
        </div>
      </div>
      <div key="no-access" v-else>
        <div class="container">
          <div class="no-access-wrapper">
            <NoAvailableContent
              :header="$t('header.noAvailablePrograms')"
              :title="$t('supportText.waitToBeAdded')"
              classes="no-programs"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import ActivityService from '@/services/activity.service';
import StudentService from '@/services/student.service';
import Pagination from '@/components/Pagination.vue';
import ProgramCardStudent from '@/components/Cards/ProgramCardStudent.vue';
import NoAvailableContent from '@/components/NoAvailableContent.vue';
import Loader from '@/components/Loader/Loader.vue';

export default {
  name: 'Dashboard',
  components: {
    ProgramCardStudent,
    Pagination,
    NoAvailableContent,
    Loader,
  },
  data: () => ({
    programs: [],
    loading: true,
    pagination: {
      perPage: 12,
      totalCount: 1,
    },
    loadingError: false,
  }),
  async created() {
    const savedPaginationPosition = +localStorage.getItem('lms-programs-page') ?? 1;
    if (savedPaginationPosition > 1) {
      this.$route.query.page = savedPaginationPosition;
      window.history.replaceState(null, null, `?page=${savedPaginationPosition}`);
    }

    await Promise.all([
      StudentService.getPrograms({
        page: this.$route.query.page || 1,
        per_page: this.pagination.perPage,
      }),
    ]).then(
      ([{ data: programs }]) => {
        if (programs.length) {
          this.programs = programs;
        } else {
          this.programs = [];
          localStorage.removeItem('lms-programs-page');
          window.history.replaceState(null, null, '');
        }

        this.pagination.totalCount = 0;
      },
      (error) => {
        console.log(error);
        this.loadingError = true;
      },
    );
    this.loading = false;
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.pagination.totalCount / this.pagination.perPage);
    },
    paginationLimitButtons() {
      const currentPage = this.$route.query.page ?? 1;
      if (+currentPage < 5 || +currentPage > this.numberOfPages - 4) {
        return 4;
      }
      return 5;
    },
  },
  methods: {
    fetchProgramsByPage(page) {
      ActivityService.getPrograms({
        page,
        per_page: this.pagination.perPage,
      })
        .then(({ data }) => (this.programs = data ?? []))
        .catch(console.log);
    },
  },
  watch: {
    '$route.query': {
      handler(query) {
        const newPageQuery = query?.page ?? 1;
        localStorage.setItem('lms-programs-page', newPageQuery);
        return this.fetchProgramsByPage(newPageQuery);
      },
      deep: true,
    },
  },
};
</script>
